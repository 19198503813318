const sectionSortOrder = {
  'how-to-guides': [
    'studio-fundamentals',
    'quick-start',
    'managing-content',
    'studio-development',
    'backend-development',
    'frontend-development',
    'monitoring-and-logging',
    'security'
  ],
  'services': [
    'campaigns',
    'event-hub-service',
    'image-transformation',
    'ingest-service',
    'coremedia-tag'
  ],
  'engagement-cloud': [
    'overview',
    'clients-and-users',
    'configuring-in-queue-position',
    'managing-audios',
    'application-reference',
    'release-notes'
  ],
  'analytics': [
    'dashboards',
    'managing-listing-panels',
    'managing-aggregation-panels',
    'reference'
  ]
}

export default sectionSortOrder

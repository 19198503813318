import React from 'react'
import useNavigationData from './use-navigation-data'
import sectionSortOrder from '../section-sort-order'
import sortArticles from '../utils/sort-articles'

export const OVERVIEW_ID_PREFIX = 'ov3rv13w'

export const formatCategoryName = (name) => name ? name.replaceAll('-', ' ') : ''

export const useNavigationTree = () => {
  const data = useNavigationData()
  const [ navigation, set ] = React.useState({})

  React.useMemo(() => {

      // Abort
      if (!data || !data.allAsciidoc || !data.allDirectory) {
        return
      }


    const createTitle = (slug)  => {
      // Replace dashes with spaces and then
      // Use a regular expression to match the first character of each word
      // and replace it with its uppercase version
      let str = slug.replaceAll("-", " ");
      return str.replace(/\b\w/g, function(char) {
        return char.toUpperCase();
      });
    }
      const sortBy = (categories, rootSlug) => {
        if (sectionSortOrder.hasOwnProperty(rootSlug)) {
          // Add sorted navigation
          const definedSlugs = sectionSortOrder[ rootSlug ].map((subkey) => `${rootSlug}/${subkey}`)
          const sorted = definedSlugs.map((slug) => categories.find((category) => category.slug === slug))
          // Add unsorted items to the end
          categories.filter((category) => !definedSlugs.includes(category.slug)).forEach((category) => sorted.push(category))
          return sorted
        } else {
          return categories
        }
      }

      const makeTreeNode = (label, id, hasChildren, children, slug) => (
        { label, id, hasChildren, children, slug }
      )

      // 1st build tree from directories
      const categories = data.allDirectory.nodes.map((node) => {
        const segments = node.relativePath.split('/')
        return (
          {
            name: formatCategoryName(node.name),
            slug: node.relativePath,
            id: node.id,
            parent: segments.length > 1 ? segments[ segments.length - 2 ] : null
          }
        )
      })

      const allNodes = [ ...data.allAsciidoc.edges, ...data.allMdx.edges, ...data.allFile.edges ]

      const findChildrenOf = (parentSlug) => {
        const children = allNodes.filter((item) => {
          switch (item.node.__typename) {
              case 'Asciidoc':
              // Ignore if other versions or set and this is not the current version
              if (item.node.pageAttributes.versions && !item.node.pageAttributes.versions.startsWith(`- ${item.node.pageAttributes.version}`) ) {
                return false
              }
             // return (item.node.fields.slug.startsWith(parentSlug) || item.node.fields.slug.includes(parentSlug))
             return ((parentSlug.split("/").length === item.node.fields.slug.split("/").length-1) && item.node.fields.slug.startsWith(parentSlug))

              case 'Mdx':
              // Ignore if other versions or set and this is not the current version
              if (item.node.frontmatter.versions && !item.node.frontmatter.versions.startsWith(`- ${item.node.frontmatter.version}`)) {
                return false
              }
                // return item.node.parent.sourceInstanceName !== 'pages' && (item.node.slug.startsWith(parentSlug) || item.node.slug.includes(parentSlug))
                return ((item.node.parent.sourceInstanceName !== 'pages') && (parentSlug.split("/").length === item.node.slug.split("/").length-1) &&  item.node.slug.startsWith(parentSlug))

            case 'File':
              const fileSlug=item.node.relativePath.substring(0, item.node.relativePath.lastIndexOf("."))
              return ((parentSlug.split("/").length === fileSlug.split("/").length-1) && fileSlug.startsWith(parentSlug))

            default:
              return false
          }
        })
        children.sort(sortArticles)
        return children
      }

      const normalise = ({ node }) => {
        switch (node.__typename) {
          case 'Asciidoc':
            return ({
              id: node.id,
              title: node.document.title,
              slug: node.fields.slug.endsWith('/')
                ? node.fields.slug.substring(0, node.fields.slug.length - 1)
                : node.fields.slug
            })

          case 'Mdx':
            return ({
              id: node.id,
              title: node.frontmatter.title,
              slug: node.slug.endsWith('/')
                ? node.slug.substring(0, node.slug.length - 1)
                : node.slug
            })

          case 'File':
            const slug = node.relativePath.substring(0, node.relativePath.lastIndexOf("."))
            const title =  createTitle(slug.substring(slug.lastIndexOf("/")+1))
            return ({
              id: node.id,
              title: title,
              slug: slug
            })

          default:
            return null
        }
      }

      const getLastSlugElement = (rootSlug) => {
          const lastElement = rootSlug.split("/")
          return (lastElement[lastElement.length-1])

      }

    const makeSubCategories = (root, firstroot) => {
      let subCategories = categories.filter((category) => category.parent === getLastSlugElement(root.slug));
      sortBy(subCategories, root.slug).forEach((node) => {
        if (node != null) {
          const children = findChildrenOf(node.slug);
          const kids = [
            makeTreeNode('Overview', `${OVERVIEW_ID_PREFIX}-${node.id}`, false, null, `${node.slug}`),
            ...children.map((childNode) => {
              const normalised = normalise(childNode);
              return makeTreeNode(normalised.title, normalised.id, false, undefined, normalised.slug);
            })
          ];

          if (categories.filter((category) => (formatCategoryName(category.parent) === node.name)).length > 0) {
            makeSubCategories(node, firstroot);
          }

          newNavigation[firstroot.slug].tree.push(makeTreeNode(node.name, node.id, true, kids, node.slug));
        }
      });
      return "";
    };

      // Find root
      const roots = categories.filter((item) => item.parent === null)
      if (roots.length === 0) {
        console.error('Could not find root navigation item')
        return
      }

      const newNavigation = {}

      roots.forEach((root) => {
          newNavigation[ root.slug ] = {
              title: root.name,
              tree: [ makeTreeNode('Overview', `${OVERVIEW_ID_PREFIX}-${root.id}`, false, null, `${root.slug}`) ]
          }
          makeSubCategories(root, root)
      })

      // Set navigation
      set(newNavigation)

    }, [ data ]
  )

  return navigation
}
